@import url("https://fonts.googleapis.com/css2?family=Source+Code+Pro:wght@700&display=swap");

:root {
  --bg-color: #000;
  --text-color: #fff;
}

background {
  background-color: #121212;
}

body {
  font-family: "Geist", sans-serif;
}