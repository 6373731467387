@import url("https://fonts.googleapis.com/css2?family=Source+Code+Pro:wght@700&display=swap");

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.MuiTypography-root {
  font-family: "Source Code Pro", sans-serif !important;
  font-weight: 700 !important;
  color: white;
}

/* Add default styling for Mui buttons */
.MuiButton-root {
  font-family: "Source Code Pro", sans-serif !important;
  font-weight: 700 !important;
  color: white !important;
  background-color: #4caf50 !important;
  border: none !important;
  border-radius: 4px !important;
  padding: 10px 30px !important;
  cursor: pointer !important;
  text-align: center !important;
  text-decoration: none !important;
  display: inline-block !important;
  font-size: 16px !important;
  margin: 4px 2px !important;
  transition-duration: 0.4s !important;
}

.MuiButton-root:hover {
  background-color: #45a049 !important;
  opacity: 0.8 !important;
}